.price-card{
  width: 233.1px;
  height: 197px;
  padding: 33.5px 36.1px 30px;
  border-radius: 20px;
  background-image: linear-gradient(to bottom, #2756a9 0%, #0263c7 100%);
  box-shadow: 4px 4px 15px 0 #7a7b7b;

}
.price-card .price{
    width: 137px;
    height: 46px;
    flex-grow: 0;
    margin: 30.4px 12px 0 11.9px;
    padding: 6px 29px 12px;
    border-radius: 50px;
    background-color: #fff;
    font-weight: 700px;
    text-align: center;
    font-size: 20px;
  color: #293750;
}
.price-card .card-text{
    text-align: center;
    color:white;
    
}
.sidebar ul{
  list-style-type:none;
}
.sidebar ul li{
  flex-grow: 0;
  font-family: BaskervilleOldFace;
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.57px;
  text-align: left;
  color: #293750;
}