.report{
  margin:5px;
}
.accordion1,.accordion2 {
    max-width: 600px;
  }
  
  .accordion-title1,.accordion-title2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
  }
  .accordion-content2,.accordion-title2 p{
    font-weight: 400;
font-size: 17px;
line-height: 19px;

letter-spacing: 0.03em;

color: #293750;
    padding: 10px 32px; 
  }
  .accordion-title1 div,.accordion-title2 div{
    font-family: 'Baskerville Old Face';
    font-size: 16px;
  }
  .accordion-title1,
  .accordion-content1 ,.accordion-title2 div{
font-style: normal;
font-weight: 400;
line-height: 19px;

letter-spacing: 0.03em;

color: #293750;
    padding: 10px 32px;
    }
  .accordion-content1{
    font-size: 17px;

  }

    .form-check{
      margin-bottom:10px;
    }
    .accordion2 input{
      border:1px solid #AAAAAA;
     }
    .search-input:focus,.search-input:hover{
      outline: 0;
      border: none;
      border:1px solid #0263c7 ;
    }
   