.footer{
background: #293750;
color: white;
margin-top: 6%;
padding: 5% 3%;
}
/* .footer div{
    margin: auto;
} */
.footer-head{
font-family: 'Baskerville Old Face';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 24px;
letter-spacing: 0.01em;
padding-bottom: 2%;

}
.footer ul{
    margin-left: -8%;
}
.footer ul li{
/* gap: 26px !important; */

padding-bottom: 16px;
font-size: 14px;
cursor: pointer;
}
.notify-btn{
    padding: 8px 16px;
gap: 10px;
color:white;
border: none;
background: #0263C7;
box-shadow: 4px 4px 15px rgba(78, 78, 78, 0.25);
border-radius: 9px;
}
.footer-email{
height: 38px;
width: 63%;
padding: 2px 10px;
border:none;
background: #FFFFFF;
border-radius: 9px;
margin-bottom: 10px;
}
.footer-email:focus{
    outline: none;
}
.comming-email{
    height: 38px;
width: 30%;
padding: 2px 10px;
border:none;
background:rgb(202, 198, 198);
border-radius: 9px;
margin-bottom: 10px;
}
.comming-email:focus{
    outline: none;
}
.comming-head{
    font-family: 'Baskerville Old Face';
font-style: normal;
font-weight: 400;
font-size: 26px;
}